.uploadButton {
  font-weight: 400 !important;
}

/* .new-upload-button {
  display: inline-block;
  padding: 5px 20px;
  cursor: pointer;
  background-color: #f2f3f3;
  font-size: 19px;
  color: black;
  border: 1px solid #444;
}
input[type="file"] {
  position: absolute;
  z-index: -1;
  top: 6px;
  left: 0;
  font-size: 15px;
  color: rgb(153, 153, 153);
 
}
.button-wrap {
  position: relative;
} */
