.uploading-alert div {
  /* font-size: 0.9em !important; */
}

ul.steps,
ul.download {
  list-style: none;
  padding-inline-start: 0;
}
.upload-form .form {
  margin: auto;
  width: 50%;
  text-align: center;
  padding-top: 20px;
}

button#uploadFileButton {
  /* font-size: 15px !important; */
  padding: 0.2em 0.8em !important;
}
@media (min-width: 1500px) {
  .custom-home__header.second-section {
    padding-bottom: 30px !important;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* font-size: calc(10px + 2vmin); */
  color: white;
}

.App-link {
  color: #61dafb;
}

.file-upload-alert {
  margin-top: 1em;
}

.custom-home__header {
  background-color: rgb(35, 47, 62);
  padding: 0.5em 0;
  margin: auto !important;
}

.custom-home-main-content-area {
  margin: auto !important;
}

.custom-home__header .header-badge {
  text-align: right;
  border-radius: 50%;
}

.header-badge img {
  background-color: #ff9900;
  padding: 2px;
  border-radius: 10px;
}

.custom-home__header-title {
  color: rgb(255, 255, 255);
}

.custom-home__category,
.custom-home__header-sub-title {
  color: rgb(213, 219, 219);
  /* font-size: 12px !important; */
}

.app-footer {
  margin-top: 6em;
  position: relative;
  bottom: 0;
  left: 0;
  text-align: center;
  right: 0;
  width: 100%;
}

@media (min-width: 1500px) {
  .app-footer {
    position: fixed;
  }
}

.app-footer img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 992px) {
  .custom-home__sidebar {
    margin-top: -3em;
  }
}

.wiki-link-button {
  color: #fff !important;
  text-decoration: none;
  font-weight: 500;
  background-color: rgb(236, 114, 17);
  padding: 0.5em 1em !important;
}

.wiki-link-button:hover {
  background-color: rgb(235, 95, 87);
  text-decoration: none !important;
  transition: 0.2s background-color ease-in;
}
