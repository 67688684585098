html {
  height: 100%;
}

body {
  font-family: Open Sans, Helvetica, Arial, sans-serif;
  height: auto;
  margin: 0;
}

.uploading-alert div {
  /* font-size: 0.9em !important; */
}

ul.steps,
ul.download {
  list-style: none;
  padding-inline-start: 0;
}
.upload-form .form {
  margin: auto;
  width: 50%;
  text-align: center;
  padding-top: 20px;
}

button#uploadFileButton {
  /* font-size: 15px !important; */
  padding: 0.2em 0.8em !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* font-size: calc(10px + 2vmin); */
  color: white;
}

.App-link {
  color: #61dafb;
}

.file-upload-alert {
  margin-top: 1em;
}

.custom-home__header {
  background-color: rgb(35, 47, 62);
  padding: 0.5em 0;
}

.custom-home__header .header-badge {
  text-align: right;
  border-radius: 50%;
}

.header-badge img {
  background-color: #ff9900;
  padding: 2px;
  border-radius: 10px;
}

.custom-home__header-title {
  color: rgb(255, 255, 255);
}

.custom-home__category,
.custom-home__header-sub-title {
  color: rgb(213, 219, 219);
  /* font-size: 12px !important; */
}

.upload-file-section {
  margin-top: 2em;
}

.app-footer {
  margin-top: 6em;
  position: relative;
  bottom: 0;
  left: 0;
  text-align: center;
  right: 0;
  width: 100%;
}

.login-form {
  margin-top: 5em;
}

.login-form h1 {
  color: #22303e;
}

@media (min-width: 992px) {
  .custom-home__sidebar {
    margin-top: -3em;
  }
}
